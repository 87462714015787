<style></style>
<template>
  <nav
    class="navbar navbar-default navbar-fixed-top"
    style="position: sticky"
    v-if="campus.subdomain != 'leveltest'"
  >
    <div class="container">
      <!-- Brand and toggle get grouped for better mobile display -->
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#bs-example-navbar-collapse-1"
          aria-expanded="false"
          style="z-index: 9999"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>

        <div class="logo-campus" style="position: absolute">
          <a href="/">
            <img
              src="./assets/images/logo2_childu.png"
              style="width: 130px"
              class="logo-campus-img"
            /> </a
          >&nbsp;
          <div
            class="campus-name"
            style="
              display: inline-block;
              top: 16px;
              font-size: 20px;
              font-weight: 700;
              color: #0c3276;
              position: relative;
            "
          >
            {{ campus.name }}&nbsp;&nbsp;
            <span
              class="campus-tel"
              style="display: block; font-size: 16px; margin-top: -4px"
            >
              TEL&nbsp;:&nbsp;{{ campus.tel }}</span
            >
          </div>
          <span>
            <!--우수학원 뱃지-->
            <img
              src="https://mypathkr.com/img/best_campus.gif"
              style="height: 32px; left: -550px; position: relative"
              v-if="campus.badge == '1'"
            />
            <img
              src="https://mypathkr.com/img/EE_Campus.gif"
              style="height: 32px; left: -560px; position: relative"
              v-if="campus.badge == '2'"
            />
            <img
              src="https://mypathkr.com/img/Spcl_Campus.gif"
              style="height: 32px; left: -590px; position: relative"
              v-if="campus.badge == '3'"
            />
          </span>
        </div>
      </div>

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <ul class="nav navbar-nav">
          <li class="nav-li1">
            <a href="/info">학원소개</a>
          </li>

          <li class="nav-li2">
            <a href="/brand">프로그램</a>
          </li>

          <li class="nav-li3">
            <a href="/notice">학원소식</a>
          </li>

          <li class="nav-li4">
            <a href="/event">이벤트</a>
          </li>

          <li class="nav-li5">
            <a href="/faq">고객지원</a>
          </li>

          <div class="hover-drop">
            <div class="drop-inner">
              <div
                id="nav-column"
                style="
                  display: none;
                  width: 310px;
                  position: absolute;
                  left: 200px;
                  z-index: 10;
                  margin-top: -20px !important;
                "
              >
                <ul class="sub event-img">
                  <li>
                    <router-link to="../articles/4777">
                      <img
                        src="src/assets/images/childu_event_bn.png"
                        style="border-radius: 30px"
                    /></router-link>
                  </li>
                </ul>
              </div>
              <div id="nav-column" class="nav-column1">
                <ul class="sub sub1">
                  <li><a href="/info">인사말</a></li>
                  <li><a href="/info#map">위치안내</a></li>
                  <!--<li><a href="https://www.useschool.co.kr/uracle_value/" target="_blank">Ulacle</a></li> -->
                </ul>
              </div>
              <div id="nav-column" class="nav-column2">
                <ul class="sub sub2">
                  <li><a href="/brand">브랜드 소개</a></li>
                  <li><a href="/study">학습구성</a></li>
                  <li><a href="/curri">커리큘럼</a></li>
                </ul>
              </div>
              <div id="nav-column" class="nav-column3">
                <ul class="sub sub3">
                  <li><a href="/notice">ChildU NEWS</a></li>
                  <li><a href="/campus_notice">Campus NEWS</a></li>
                  <li><a href="/gallery">Campus GALLERY</a></li>
                </ul>
              </div>
              <div id="nav-column" class="nav-column4">
                <ul class="sub sub4">
                  <li><a href="/event">ChidU EVENT</a></li>
                </ul>
              </div>
              <div id="nav-column" class="nav-column5">
                <ul class="sub sub5">
                  <li><a href="/faq">ChildU FAQ</a></li>
                  <li>
                    <a
                      href="http://mypathkr.com/data/230523차일드유_기술시스템가이드.pdf"
                      target="_blank"
                      >시스템 가이드</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ul>

        <div id="nav-column" class="form-inline" v-if="!user.id">
          <a href="https://bizstudy.useschool.co.kr/default.asp">
            <button class="btn sign-btn" style="background-color: #0c3276">
              <p style="color: #fff">학습관 입장</p>

              <!--<img
                src="https://mypathkr.com/img/btn_login.png"
                onmouseover="this.src='https://mypathkr.com/img/btn_login_on.png';"
                onmouseout="this.src='https://mypathkr.com/img/btn_login.png';"
              />-->
            </button>
          </a>
        </div>
      </div>
      <!-- /.navbar-collapse -->
    </div>
    <!-- /.container-fluid -->
  </nav>

  <div class="content-wrap" style="background: #fff">
    <article id="post-34999" class="post-34999 page type-page status-publish">
      <router-view v-if="campus.subdomain == 'leveltest' || campus.id" />
    </article>
  </div>

  <div
    v-if="campus.subdomain != 'leveltest'"
    class="footer-btn"
    style="
      height: 46px;
      line-height: 46px;
      background: #2b2a2a;
      margin-bottom: -28px;
      border-bottom: 1px solid #4b4b4b;
    "
  >
    <ul style="display: flex; align-items: center; justify-content: center">
      <li class="ft-btn" style="float: left">
        <a
          href="http://mypathkr.com/data/230523차일드유_기술시스템가이드.pdf"
          target="_blank"
          >기술/시스템 매뉴얼</a
        >
      </li>
      <li class="ft-btn" style="float: left">
        <a href="https://www.367.co.kr" target="_blank">원격지원센터</a>
      </li>
      <li class="ft-btn" style="float: left">
        <a
          onclick="window.open('https://www.mypathkr.com/inc/privacy.asp', 'target', 'top=100, left=300, width=800, height=600, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=no');"
          target="_blank"
          >개인정보 취급방침</a
        >
      </li>
      <li class="ft-btn" style="float: left">
        <a
          onclick="window.open('https://www.mypathkr.com/inc/agree.asp', 'target', 'top=100, left=300, width=800, height=600, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=no');"
          target="_blank"
          >회원이용약관</a
        >
      </li>
      <li class="ft-btn" style="float: left">
        <a
          href="https://lms.mypathkr.com/"
          target="_blank"
          style="font-weight: 500"
          >ADMIN</a
        >
      </li>
    </ul>
  </div>

  <div class="cambium-footer" v-if="campus.subdomain != 'leveltest'">
    <div class="container text-center">
      <div class="col-md-3" style="text-align: left; text-align: center">
        <!-- <a href="https://mypahtkr.com/" target="_blank"></a> -->
        <a href="/" >
          <img
            src="./assets/images/logo_childu_w.png"
            alt="Cambium Learning Group"
            style="width: 160px"
            class="ft-logo"
          /><br />
          <span style="color: #a3a3a3; font-size: 20px">{{ campus.name }}</span>
        </a>
      </div>

      <div class="col-md-9" style="text-align: left; color: #ededed">
        <div class="ft-info" style="display: block; padding-left: 50px">
          <span style="font-size: 16px">{{ campus.name }}</span
          >&nbsp;
          <span style="font-size: 14px; line-height: 12px; color: #ededed">
            {{ campus.address1 }} {{ campus.address2 }}<br />
            TEL : {{ campus.tel }}&nbsp;&nbsp;│&nbsp;&nbsp; EMAIL : {{ campus.email }}
            <br />
            <!-- 학원등록번호, 교습과목, 교습시간을 각각 체크하고 표시 -->
            <div v-if="campus.cam_number || campus.cam_class || campus.cam_time">
              <span v-if="campus.cam_number" style="font-size: 14px; line-height: 180%; color: #ededed">
                학원등록번호 : {{ campus.cam_number }}
              </span>

              <!-- 교습과목이 있고 학원등록번호도 있을 경우에만 구분자를 추가 -->
              <span v-if="campus.cam_class">
                <span v-if="campus.cam_number" style="font-size: 14px; line-height: 180%; color: #ededed">
                  &nbsp;&nbsp;│&nbsp;&nbsp;
                </span>
                <span style="font-size: 14px; line-height: 180%; color: #ededed">
                  교습과목 : {{ campus.cam_class }}
                </span>
              </span>

              <!-- 교습시간이 있고 앞의 항목(등록번호 또는 교습과목)이 있을 경우에만 구분자를 추가 -->
              <span v-if="campus.cam_time">
                <span v-if="campus.cam_number || campus.cam_class" style="font-size: 14px; line-height: 180%; color: #ededed">
                  &nbsp;&nbsp;│&nbsp;&nbsp;
                </span>
                <span style="font-size: 14px; line-height: 180%; color: #ededed">
                  교습시간 : {{ campus.cam_time }}
                </span>
              </span>
              <br />
            </div>

            <!-- 교습비용이 있을 때만 표시 -->
            <span v-if="campus.cam_expense" style="font-size: 14px; line-height: 180%; color: #ededed">
              교습비용 : {{ campus.cam_expense }}
            </span>
            <li
              class="logo-gather"
              style="list-style-type: none; margin-left: -10px"
            >
              <img src="https://mypathkr.com/img/bottom_logo.png" />
            </li>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
export default {
  data: () => ({
    map: null,
    campus: {},
    baseUrl: "https://api.mypathkr.com",
    accessToken: "",
    loginID: {
      username: "",
      password: "",
    },
    user: {
      subscriptions: [],
    },
    popups: [],
  }),
  methods: {
    no_today() {
      localStorage.setItem("no_again_til", new Date() + 86400000);
    },
    login() {
      let form = new FormData();
      form.append("grant_type", "password");
      form.append("username", this.loginID.username);
      form.append("password", this.loginID.password);
      axios
        .post(this.baseUrl + "/oauth/login", form)
        .then((response) => {
          return new Promise((resolve) => {
            if (response.data.error) {
              $.notify(
                {
                  // options
                  message: "로그인할 수 없습니다. " + response.data.error,
                },
                {
                  // settings
                  type: "danger",
                  z_index: 9999,
                }
              );
            } else {
              this.accessToken = response.data.access_token;
              $.notify(
                {
                  // options
                  message: "로그인 되었습니다.",
                },
                {
                  // settings
                  type: "success",
                  z_index: 9999,
                }
              );
              resolve(response.data);
            }
          });
        })
        .then(() => {
          axios
            .get(this.baseUrl + "/me", {
              headers: {
                Authorization: "Bearer " + this.accessToken,
              },
            })
            .then((response) => {
              this.user = { ...this.user, ...response.data.result };
            });
          axios
            .get(this.baseUrl + "/me/subscriptions?status=ACTIVE", {
              headers: {
                Authorization: "Bearer " + this.accessToken,
              },
            })
            .then((response) => {
              this.user.subscriptions = response.data.result;
            });
        })
        .catch((error) => {
          console.log("failed", error);
        });
    },
    goStudy(alt) {
      if (!this.user.subscriptions.length) {
        $.notify(
          {
            // options
            message: "학습 중인 상품이 없습니다.",
          },
          {
            // settings
            type: "danger",
            z_index: 9999,
          }
        );
      } else {
        var study = this.user.subscriptions[alt ? 1 : 0];
        if (study.product == "MYPATH") {
          window.open("https://app.edgenuity.com/?code=UBESTAPRO");
        } else if (study.product == "MYUSE") {
          window.open("https://bizstudy.useschool.co.kr/");
        }
      }
    },
  },
  mounted() {
    // 학원 정보 수신
    //console.log(this.$root);
    this.campus.subdomain = window.location.hostname.split(".")[0];
    //this.campus.subdomain = 'leveltest'
    if (this.campus.subdomain != "leveltest") {
      axios
        .get(this.baseUrl + "/campus/" + window.location.hostname.split(".")[0])
        .then((response) => {
          if (response.data.error) {
            alert("학원정보를 불러올 수 없습니다.\n" + response.data.error);
          } else {
            this.campus = response.data.result;
            console.log(this.campus.badge);
            this.campus.subdomain = window.location.hostname.split(".")[0];
            this.$root.name = this.campus.name;
            this.$root.id = this.campus.id;
          }
        });
    }
  },
};
</script>
